import gsap from "gsap"; // Import GSAP library
import { useGSAP } from "@gsap/react"; // Import custom hook for using GSAP with React
import { ScrollTrigger } from "gsap/all"; // Import ScrollTrigger plugin from GSAP
gsap.registerPlugin(ScrollTrigger); // Register ScrollTrigger plugin with GSAP
import { useEffect, useRef, useState } from "react"; // Import React hooks

import { hightlightsSlides } from "../constants"; // Import constants
import { pauseImg, playImg, replayImg } from "../utils"; // Import utility functions

const VideoCarousel = () => {
  // Define state variables using hooks
  const videoRef = useRef([]); // Reference for video elements
  const videoSpanRef = useRef([]); // Reference for span elements
  const videoDivRef = useRef([]); // Reference for div elements

  const [video, setVideo] = useState({
    // State for video properties
    isEnd: false, // Flag indicating if the video has ended
    startPlay: false, // Flag indicating if the video has started playing
    videoId: 0, // ID of the current video
    isLastVideo: false, // Flag indicating if it's the last video
    isPlaying: false, // Flag indicating if the video is currently playing
  });

  const [loadedData, setLoadedData] = useState([]); // State for loaded metadata of videos
  const { isEnd, isLastVideo, startPlay, videoId, isPlaying } = video; // Destructure video state

  // Run GSAP animations using custom hook when videoId or isEnd changes
  useGSAP(() => {
    // Animation to move the slider to display the current video
    gsap.to("#slider", {
      transform: `translateX(${-100 * videoId}%)`,
      duration: 2,
      ease: "power2.inOut",
    });

    // Animation to play the video when it enters the view
    gsap.to("#video", {
      scrollTrigger: {
        trigger: "#video",
        toggleActions: "restart none none none",
      },
      onComplete: () => {
        setVideo((pre) => ({
          ...pre,
          startPlay: true,
          isPlaying: true,
        }));
      },
    });
  }, [isEnd, videoId]);

  // Run side effect to update progress bar and handle video playback
  useEffect(() => {
    // Function to update progress bar and handle video playback
    let currentProgress = 0; // Variable to store current progress of progress bar
    let span = videoSpanRef.current; // Reference to span elements

    if (span[videoId]) {
      // Animation to move the indicator and update progress bar
      let anim = gsap.to(span[videoId], {
        onUpdate: () => {
          const progress = Math.ceil(anim.progress() * 100); // Calculate progress percentage

          // Update progress bar width based on window size
          gsap.to(videoDivRef.current[videoId], {
            width:
              window.innerWidth < 760
                ? "10vw"
                : window.innerWidth < 1200
                ? "10vw"
                : "4vw",
          });

          // Update progress bar width and color
          gsap.to(span[videoId], {
            width: `${currentProgress}%`,
            backgroundColor: "white",
          });
        },

        // Callback when the video playback is complete
        onComplete: () => {
          if (isPlaying) {
            // Reset progress bar and indicator when video is playing
            gsap.to(videoDivRef.current[videoId], {
              width: "12px",
            });
            gsap.to(span[videoId], {
              backgroundColor: "#afafaf",
            });
          }
        },
      });

      if (videoId == 0) {
        anim.restart(); // Restart animation for the first video
      }

      // Function to update progress bar based on video playback
      const animUpdate = () => {
        anim.progress(
          videoRef.current[videoId].currentTime /
            hightlightsSlides[videoId].videoDuration
        );
      };

      if (isPlaying) {
        gsap.ticker.add(animUpdate); // Add ticker to update progress bar
      } else {
        gsap.ticker.remove(animUpdate); // Remove ticker when video is paused
      }
    }
  }, [videoId, startPlay]);

  // Run side effect to handle video playback
  useEffect(() => {
    // Check if video is loaded and handle playback accordingly
    if (loadedData.length > 3) {
      if (!isPlaying) {
        videoRef.current[videoId].pause(); // Pause video if it's not playing
      } else {
        startPlay && videoRef.current[videoId].play(); // Play video if it's playing
      }
    }
  }, [startPlay, videoId, isPlaying, loadedData]);

  // Function to handle video playback events
  const handleProcess = (type, i) => {
    switch (type) {
      case "video-end":
        setVideo((pre) => ({ ...pre, isEnd: true, videoId: i + 1 })); // Move to next video when current video ends
        break;
      case "video-last":
        setVideo((pre) => ({ ...pre, isLastVideo: true })); // Set flag when it's the last video
        break;
      case "video-reset":
        setVideo((pre) => ({ ...pre, videoId: 0, isLastVideo: false })); // Reset video to the beginning
        break;
      case "pause":
        setVideo((pre) => ({ ...pre, isPlaying: !pre.isPlaying })); // Toggle play/pause state
        break;
      case "play":
        setVideo((pre) => ({ ...pre, isPlaying: !pre.isPlaying })); // Toggle play/pause state
        break;
      default:
        return video; // Return current video state for unknown actions
    }
  };

  // Function to handle loaded metadata of videos
  const handleLoadedMetaData = (i, e) => setLoadedData((pre) => [...pre, e]);

  return (
    <>
      {/* Iterate over video slides and display videos */}
      <div className="flex items-center">
        {hightlightsSlides.map((list, i) => (
          <div key={list.id} id="slider" className="sm:pr-20 pr-10">
            <div className="video-carousel_container">
              <div className="w-full h-full flex-center rounded-3xl overflow-hidden bg-black">
                {/* Video element */}
                <video
                  id="video"
                  playsInline={true}
                  className={`${
                    list.id === 2 && "translate-x-44"
                  } pointer-events-none`}
                  preload="auto"
                  muted
                  ref={(el) => (videoRef.current[i] = el)}
                  onEnded={() =>
                    i !== 3
                      ? handleProcess("video-end", i)
                      : handleProcess("video-last")
                  }
                  onPlay={() =>
                    setVideo((pre) => ({ ...pre, isPlaying: true }))
                  }
                  onLoadedMetadata={(e) => handleLoadedMetaData(i, e)}
                >
                  <source src={list.video} type="video/mp4" />
                </video>
              </div>

              {/* Text content to be display under the video */}
              <div className="absolute top-12 left-[5%] z-10">
                {list.textLists.map((text, i) => (
                  <p key={i} className="md:text-2xl text-xl font-medium">
                    {text}
                  </p>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Indicator for video progress */}
      <div className="relative flex-center mt-10">
        <div className="flex-center py-5 px-7 bg-gray-300 backdrop-blur rounded-full">
          {videoRef.current.map((_, i) => (
            <span
              key={i}
              className="mx-2 w-3 h-3 bg-gray-200 rounded-full relative cursor-pointer"
              ref={(el) => (videoDivRef.current[i] = el)}
            >
              <span
                className="absolute h-full w-full rounded-full"
                ref={(el) => (videoSpanRef.current[i] = el)}
              />
            </span>
          ))}
        </div>

        {/* Button to control video playback */}
        <button className="control-btn">
          <img
            src={isLastVideo ? replayImg : !isPlaying ? playImg : pauseImg}
            alt={isLastVideo ? "replay" : !isPlaying ? "play" : "pause"}
            onClick={
              isLastVideo
                ? () => handleProcess("video-reset")
                : !isPlaying
                ? () => handleProcess("play")
                : () => handleProcess("pause")
            }
          />
        </button>
      </div>
    </>
  );
};

export default VideoCarousel;
